import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		env: process.env,
		referentiels: [],
		referentielsMulti: [],
		derniereRecherche: {
			strRecherche: '',
			resultats: [],
			date: new Date().getTime()
		}
	},
	getters: {
		appVersion: (state) => {
			return state.env.VUE_APP_VERSION;
		},
		referentielsHAS: (state) => {
			return state.referentielsMulti.filter((ref) => {
				if (ref.cat_finess !== '' && ref.cat_finess !== null) {
					return true;
				} else if (ref.type_structure !== '' && ref.type_structure !== null) {
					return true;
				}

				return false;
			});
		}
	},
	mutations: {
		setReferentiels(state, refs) {
			state.referentiels = refs;
		},
		setReferentielsMulti(state, refs) {
			state.referentielsMulti = refs;
		},
		setDerniereRecherche(state, donnees) {
			state.derniereRecherche.strRecherche = donnees.recherche;
			state.derniereRecherche.resultats = donnees.resultats || [];
			state.derniereRecherche.date = new Date().getTime();
		}
	},
	actions: {
		fetchReferentiels({ commit }) {
			return fetch(process.env.VUE_APP_API_ADMIN + 'referentiels/listeBDD')
				.then((rep) => rep.json())
				.catch((err) => {
					console.warn(err);

					return [];
				})
				.then((ret) => {
					commit('setReferentiels', ret);
					return true;
				});
		},
		fetchReferentielsMulti({ commit }) {
			let urlApi = 'https://multi.qualiteval.fr/api/referentiels/liste';
			if (process.env.NODE_ENV === 'development') {
				urlApi = 'https://dev-multi.qualiteval.fr/api/referentiels/liste';
			}
			return fetch(urlApi)
				.then((rep) => rep.json())
				.catch((err) => {
					console.warn(err);

					return {
						state: 0,
						referentiels: []
					};
				})
				.then((ret) => {
					commit('setReferentielsMulti', ret.referentiels);
					return true;
				});
		}
	},
	modules: {}
});

import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import NotFoundComponent from '../views/NotFoundComponent.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView,
		// Ajout des métas suivant https://alligator.io/vuejs/vue-router-modify-head/
		meta: {
			title: 'Arrière-boutique Qualitéval',
			metaTags: [
				{
					name: 'description',
					content: 'L’utilitaire interne de gestion Qualitéval'
				},
				{
					property: 'og:description',
					content: 'L’utilitaire interne de gestion Qualitéval'
				}
			]
		}
	},
	{
		path: '/clients',
		name: 'clients',
		// route level code-splitting
		// this generates a separate chunk (clients.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "clients" */ '../views/clients/ClientAccueil.vue'),
		meta: {
			title: 'Suivi des clients - Qualitéval'
		}
	},
	{
		path: '/client/:id_client([0-9]+)',
		name: 'clientDetails',
		component: () => import(/* webpackChunkName: "clientDetails" */ '../views/clients/ClientDetails.vue'),
		meta: {
			title: 'Détails d’un client - Qualitéval'
		}
	},
	{
		path: '/licences',
		name: 'licences',
		component: () => import(/* webpackChunkName: "licences" */ '../views/licence/LicenceAccueil.vue'),
		meta: {
			title: 'Suivi des licences - Qualitéval'
		}
	},
	{
		path: '/licences/licence/:num_licence([0-9]{3}-[0-9]{4}-[0-9]{4})',
		name: 'licenceDetails',
		component: () => import(/* webpackChunkName: "licence_details" */ '../views/licence/LicenceDetails.vue'),
		meta: {
			title: 'Détails d’une licence - Qualitéval'
		}
	},
	{
		path: '/licences/licence/:num_licence([0-9]{3}-[0-9]{4}-[0-9]{4})/personne/:id_personne([0-9]+)',
		name: 'licencePersonneDetails',
		component: () => import(/* webpackChunkName: "licence_details" */ '../views/clients/PersonneInfos.vue'),
		meta: {
			title: 'Détails d’une personne - Qualitéval'
		}
	},
	{
		path: '/ventes',
		name: 'ventes',
		// route level code-splitting
		// this generates a separate chunk (clients.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import('../views/ventes/VentesAccueil.vue'),
		meta: {
			title: 'Gestion des ventes - Qualitéval'
		}
	},
	{
		path: '/ventes/facture/:idFacture([0-9]+)',
		name: 'factureDetails',
		component: () => import(/* webpackChunkName: "factureDetails" */ '../components/BlocFacture.vue'),
		props: (route) => ({ idFacture: parseInt(route.params.idFacture, 10) }),
		meta: {
			title: 'Détails d’une facture - Qualitéval'
		}
	},
	{
		path: '/referentiels',
		name: 'referentiels',
		component: () => import('../views/referentiels/ReferentielsAccueil.vue'),
		meta: {
			title: 'Gestion des référentiels - Qualitéval'
		}
	},
	{
		path: '/licencecreation',
		name: 'licencecreation',
		component: () => import('../views/outils/LicenceCreationAccueil.vue'),
		meta: {
			title: 'Création d’une licence - Qualitéval'
		}
	},
	{
		path: '/actualites',
		name: 'actualites',
		component: () => import('../views/outils/ActualitesAccueil.vue'),
		meta: {
			title: 'Liste des actualités - Qualitéval'
		}
	},
	// Catch-all 404
	{ path: '*', component: NotFoundComponent }
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

// https://alligator.io/vuejs/vue-router-modify-head/
router.beforeEach((to, from, next) => {
	// This goes through the matched routes from last to first, finding the closest route with a title.
	// eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.title);

	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);
	// const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

	// If a route with a title was found, set the document (page) title to that value.
	if (nearestWithTitle) {
		document.title = nearestWithTitle.meta.title;
	}

	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));

	// Skip rendering meta tags if there are none.
	if (!nearestWithMeta) {
		return next();
	}

	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags
		.map((tagDef) => {
			const tag = document.createElement('meta');

			Object.keys(tagDef).forEach((key) => {
				tag.setAttribute(key, tagDef[key]);
			});

			// We use this to track which meta tags we create, so we don't interfere with other ones.
			tag.setAttribute('data-vue-router-controlled', '');

			return tag;
		})
		// Add the meta tags to the document head.
		.forEach((tag) => document.head.appendChild(tag));

	next();
});

export default router;

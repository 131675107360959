import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import BootstrapVue from 'bootstrap-vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import Validations from 'vuelidate';

Vue.use(BootstrapVue);
Vue.use(Validations);

library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

Promise.all([store.dispatch('fetchReferentiels'), store.dispatch('fetchReferentielsMulti')]).then(() => {
	new Vue({
		router,
		store,
		render: (h) => h(App)
	}).$mount('#app');
});
